import { Token } from './token'
import { ChainId, ZERO_ADDRESS } from '../constants'

/**
 * Known WETH9 implementation addresses, used in our implementation of Ether#wrapped
 */
export const WETH9: { [chainId: number]: Token } = {
  [1]: new Token(1, '0xC02aaA39b223FE8D0A0e5C4F27eAD9083C756Cc2', 18, 'WETH', 'Wrapped Ether'),
  [3]: new Token(3, '0xc778417E063141139Fce010982780140Aa0cD5Ab', 18, 'WETH', 'Wrapped Ether'),
  [4]: new Token(4, '0xc778417E063141139Fce010982780140Aa0cD5Ab', 18, 'WETH', 'Wrapped Ether'),
  [5]: new Token(5, '0xB4FBF271143F4FBf7B91A5ded31805e42b2208d6', 18, 'WETH', 'Wrapped Ether'),
  [42]: new Token(42, '0xd0A1E359811322d97991E03f863a0C30C2cF029C', 18, 'WETH', 'Wrapped Ether'),

  [10]: new Token(10, '0x4200000000000000000000000000000000000006', 18, 'WETH', 'Wrapped Ether'),
  [69]: new Token(69, '0x4200000000000000000000000000000000000006', 18, 'WETH', 'Wrapped Ether'),

  [42161]: new Token(42161, '0x82aF49447D8a07e3bd95BD0d56f35241523fBab1', 18, 'WETH', 'Wrapped Ether'),
  [421611]: new Token(421611, '0xB47e6A5f8b33b3F17603C83a0535A9dcD7E32681', 18, 'WETH', 'Wrapped Ether'),
}

// @todo merge WETH with WETH9?
export const WETH = {
  [ChainId.MUMBAI]: new Token(
    ChainId.MUMBAI,
    '0x5B67676a984807a212b1c59eBFc9B3568a474F0a',
    18,
    'WMATIC',
    'Wrapped MATIC'
  ),
  [ChainId.MATIC]: new Token(
    ChainId.MATIC,
    '0x0d500B1d8E8eF31E21C99d1Db9A6444d3ADf1270',
    18,
    'WMATIC',
    'Wrapped MATIC'
  ),
  [ChainId.FUJI]: new Token(ChainId.FUJI, '0xd00ae08403B9bbb9124bB305C09058E32C39A48c', 18, 'WAVAX', 'Wrapped AVAX'),
  [ChainId.AVALANCHE]: new Token(
    ChainId.AVALANCHE,
    '0xB31f66AA3C1e785363F0875A1B74E27b85FD66c7',
    18,
    'WAVAX',
    'Wrapped AVAX'
  ),
  [ChainId.FANTOM_TESTNET]: new Token(
    ChainId.FANTOM_TESTNET,
    '0xf1277d1Ed8AD466beddF92ef448A132661956621',
    18,
    'WFTM',
    'Wrapped FTM'
  ),
  [ChainId.FANTOM]: new Token(ChainId.FANTOM, '0x21be370D5312f44cB42ce377BC9b8a0cEF1A4C83', 18, 'WFTM', 'Wrapped FTM'),
  [ChainId.HUOBIECO_TESTNET]: new Token(
    ChainId.HUOBIECO_TESTNET,
    '0x7aF326B6351C8A9b8fb8CD205CBe11d4Ac5FA836',
    18,
    'WHT',
    'Wrapped HT'
  ),
  [ChainId.HUOBIECO]: new Token(
    ChainId.HUOBIECO,
    '0x5545153CCFcA01fbd7Dd11C0b23ba694D9509A6F',
    18,
    'WHT',
    'Wrapped HT'
  ),
  [ChainId.REEF]: new Token(ChainId.REEF, ZERO_ADDRESS, 18, 'WREEF', 'Wrapped REEF'),
  [ChainId.XDAI]: new Token(ChainId.XDAI, '0xe91D153E0b41518A2Ce8Dd3D7944Fa863463a97d', 18, 'WXDAI', 'Wrapped XDAI'),
  [ChainId.FUSE]: new Token(ChainId.FUSE, '0x0BE9e53fd7EDaC9F859882AfdDa116645287C629', 18, 'WFUSE', 'Wrapped FUSE'),
  [ChainId.OPTIMISM]: new Token(
    ChainId.OPTIMISM,
    '0x4200000000000000000000000000000000000006',
    18,
    'WETH',
    'Wrapped ETH'
  ),
  [ChainId.OPTIMISM_TESTNET]: new Token(ChainId.OPTIMISM_TESTNET, ZERO_ADDRESS, 18, 'WKOR', 'Wrapped KOR'),
  [ChainId.KUCOIN]: new Token(ChainId.KUCOIN, '0x4446Fc4eb47f2f6586f9fAAb68B3498F86C07521', 18, 'WKCS', 'Wrapped KCS'),
  [ChainId.KUCOIN_TESTNET]: new Token(ChainId.KUCOIN_TESTNET, ZERO_ADDRESS, 18, 'WtKCS', 'Wrapped tKCS'),
  [ChainId.ETHERLITE]: new Token(ChainId.ETHERLITE, ZERO_ADDRESS, 18, 'WETL', 'Wrapped ETL'),
  [ChainId.ETHEREUM_CLASSIC]: new Token(ChainId.ETHEREUM_CLASSIC, ZERO_ADDRESS, 18, 'WETC', 'Wrapped ETC'),
  [ChainId.ETHEREUM_CLASSIC_TESTNET]: new Token(
    ChainId.ETHEREUM_CLASSIC_TESTNET,
    ZERO_ADDRESS,
    18,
    'WCETC',
    'Wrapped CETC'
  ),
  [ChainId.MOONBEAM]: new Token(
    ChainId.MOONBEAM,
    '0xAcc15dC74880C9944775448304B263D191c6077F',
    18,
    'WGLMR',
    'Wrapped GLMR'
  ),
  [ChainId.MOONBEAM_TESTNET]: new Token(ChainId.MOONBEAM_TESTNET, ZERO_ADDRESS, 18, 'WDEV', 'Wrapped DEV'),
  [ChainId.CLOVER]: new Token(ChainId.CLOVER, '0x1376C97C5c512d2d6F9173A9A3A016B6140b4536', 18, 'WCLV', 'Wrapped CLV'),
  [ChainId.CLOVER_TESTNET]: new Token(ChainId.CLOVER_TESTNET, ZERO_ADDRESS, 18, 'WCLV', 'Wrapped CLV'),
  [ChainId.CELO]: new Token(ChainId.CELO, ZERO_ADDRESS, 18, 'WCELO', 'Wrapped CELO'),
  [ChainId.ALFAJORES]: new Token(ChainId.ALFAJORES, ZERO_ADDRESS, 18, 'WCELO', 'Wrapped CELO'),
  [ChainId.ARBITRUM_TESTNET]: new Token(ChainId.ARBITRUM_TESTNET, ZERO_ADDRESS, 18, 'WETH', 'Wrapped ETH'),
  [ChainId.ARBITRUM]: new Token(
    ChainId.ARBITRUM,
    '0x82aF49447D8a07e3bd95BD0d56f35241523fBab1',
    18,
    'WETH',
    'Wrapped ETH'
  ),
  [ChainId.FLARE]: new Token(ChainId.FLARE, ZERO_ADDRESS, 18, 'WFLR', 'Wrapped FLR'),
  [ChainId.FLARE_TESTNET]: new Token(ChainId.FLARE_TESTNET, ZERO_ADDRESS, 18, 'WCFLR', 'Wrapped CFLR'),
  [ChainId.FUSION]: new Token(ChainId.FUSION, ZERO_ADDRESS, 18, 'WFSN', 'Wrapped FSN'),
  [ChainId.HARMONY]: new Token(
    ChainId.HARMONY,
    '0xcF664087a5bB0237a0BAd6742852ec6c8d69A27a',
    18,
    'WONE',
    'Wrapped ONE'
  ),
  [ChainId.OKEX]: new Token(ChainId.OKEX, '0x8F8526dbfd6E38E3D8307702cA8469Bae6C56C15', 18, 'WOKT', 'Wrapped OKT'),
  [ChainId.IOTEX]: new Token(ChainId.IOTEX, '0xA00744882684C3e4747faEFD68D283eA44099D03', 18, 'WIOTX', 'Wrapped IOTX'),
  [ChainId.HOO]: new Token(ChainId.HOO, '0x3EFF9D389D13D6352bfB498BCF616EF9b1BEaC87', 18, 'WHOO', 'Wrapped HOO'),
  [ChainId.ELAETH]: new Token(ChainId.ELAETH, '0x517E9e5d46C1EA8aB6f78677d6114Ef47F71f6c4', 18, 'WELA', 'Wrapped ELA'),
  [ChainId.MOONRIVER]: new Token(
    ChainId.MOONRIVER,
    '0x98878B06940aE243284CA214f92Bb71a2b032B8A',
    18,
    'WMOVR',
    'Wrapped MOVR'
  ),
  [ChainId.TELOS]: new Token(ChainId.TELOS, '0xD102cE6A4dB07D247fcc28F366A623Df0938CA9E', 18, 'WTLOS', 'Wrapped TLOS'),
  [ChainId.CRONOS]: new Token(ChainId.CRONOS, '0x5C7F8A570d578ED84E63fdFA7b1eE72dEae1AE23', 18, 'WCRO', 'Wrapped CRO'),
  [ChainId.BTTC]: new Token(ChainId.BTTC, '0x8D193c6efa90BCFf940A98785d1Ce9D093d3DC8A', 18, 'WBTT', 'Wrapped BTT'),
  [ChainId.BTTC_TESTNET]: new Token(
    ChainId.BTTC_TESTNET,
    '0x107742EB846b86CEaAF7528D5C85cddcad3e409A',
    18,
    'WBTT',
    'Wrapped BTT'
  ),
  [ChainId.KAVA]: new Token(ChainId.KAVA, '0xc86c7C0eFbd6A49B35E8714C5f59D99De09A225b', 18, 'WKAVA', 'Wrapped KAVA'),
  [ChainId.BINANCE_TESTNET]: new Token(
    ChainId.BINANCE_TESTNET,
    '0x094616F0BdFB0b526bD735Bf66Eca0Ad254ca81F',
    18,
    'WBNB',
    'Wrapped BNB'
  ),
  [ChainId.BINANCE]: new Token(
    ChainId.BINANCE,
    '0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c',
    18,
    'WBNB',
    'Wrapped BNB'
  ),
  [ChainId.ROPSTEN]: new Token(
    ChainId.ROPSTEN,
    '0x0a180A76e4466bF68A7F86fB029BEd3cCcFaAac5',
    18,
    'WETH',
    'Wrapped ETH'
  ),
  [ChainId.ETHEREUM]: new Token(
    ChainId.ETHEREUM,
    '0xC02aaA39b223FE8D0A0e5C4F27eAD9083C756Cc2',
    18,
    'WETH',
    'Wrapped ETH'
  ),
  [ChainId.NEON_TESTNET]: new Token(
    ChainId.NEON_TESTNET,
    '0x11adC2d986E334137b9ad0a0F290771F31e9517F',
    18,
    'WNEON',
    'Wrapped NEON'
  ),
  [ChainId.NEON_MAINNET]: new Token(
    ChainId.NEON_MAINNET,
    '0x202C35e517Fa803B537565c40F0a6965D7204609',
    18,
    'WNEON',
    'Wrapped NEON'
  ),
  [ChainId.Q]: new Token(ChainId.Q, '0xd07178e3eCbC78De110Df84fe1A979D5f349784a', 18, 'WQOV', 'Wrapped QOV'),
  [ChainId.BITGERT]: new Token(
    ChainId.BITGERT,
    '0x0eb9036cbE0f052386f36170c6b07eF0a0E3f710',
    18,
    'WBRISE',
    'Wrapped BRISE'
  ),
  [ChainId.WANCHAIN]: new Token(
    ChainId.WANCHAIN,
    '0xdabD997aE5E4799BE47d6E69D9431615CBa28f48',
    18,
    'WWAN',
    'Wrapped WAN'
  ),
  [ChainId.METIS]: new Token(
    ChainId.METIS,
    '0x75cb093E4D61d2A2e65D8e0BBb01DE8d89b53481',
    18,
    'WMETIS',
    'Wrapped METIS'
  ),
  [ChainId.ASTAR]: new Token(ChainId.ASTAR, '0xAeaaf0e2c81Af264101B9129C00F4440cCF0F720', 18, 'WASTR', 'Wrapped ASTR'),
  [ChainId.ARTHERA_TESTNET]: new Token(
    ChainId.ARTHERA_TESTNET,
    '0x5A1750f9cb8A7E98e1FD618922Af276493318710',
    18,
    'WAA',
    'Wrapped AA'
  ),
  [ChainId.ARTHERA]: new Token(ChainId.ARTHERA, '0x69D349E2009Af35206EFc3937BaD6817424729F7', 18, 'WAA', 'Wrapped AA'),
  [ChainId.PULSECHAIN]: new Token(
    ChainId.PULSECHAIN,
    '0xA1077a294dDE1B09bB078844df40758a5D0f9a27',
    18,
    'WPLS',
    'Wrapped PLS'
  ),
  [ChainId.BASE]: new Token(ChainId.BASE, '0x4200000000000000000000000000000000000006', 18, 'WETH', 'Wrapped ETH'),
  [ChainId.VELAS]: new Token(ChainId.VELAS, '0xc579D1f3CF86749E05CD06f7ADe17856c2CE3126', 18, 'VLX', 'Wrapped VLX'),
  [ChainId.LINEA]: new Token(ChainId.LINEA, '0xe5D7C2a44FfDDf6b295A15c148167daaAf5Cf34f', 18, 'WETH', 'Wrapped ETH'),
  [ChainId.ROOTSTOCK]: new Token(
    ChainId.ROOTSTOCK,
    '0x542fda317318ebf1d3deaf76e0b632741a7e677d',
    18,
    'WRBTC',
    'Wrapped BTC'
  ),
  [ChainId.BOBA]: new Token(ChainId.BOBA, '0xDeadDeAddeAddEAddeadDEaDDEAdDeaDDeAD0000', 18, 'WETH', 'Wrapped ETH'),
  [ChainId.BOBA_BNB]: new Token(
    ChainId.BOBA_BNB,
    '0xC58aaD327D6D58D979882601ba8DDa0685B505eA',
    18,
    'WBOBA',
    'Wrapped BOBA'
  ),
  [ChainId.AURORA]: new Token(ChainId.AURORA, '0xC9BdeEd33CD01541e1eeD10f90519d2C06Fe3feB', 18, 'WETH', 'Wrapped ETH'),
  [ChainId.ZKSYNC]: new Token(ChainId.ZKSYNC, '0x5aea5775959fbc2557cc8789bc1bf90a239d9a91', 18, 'WETH', 'Wrapped ETH'),
  [ChainId.SCROLL]: new Token(ChainId.SCROLL, '0x5300000000000000000000000000000000000004', 18, 'WETH', 'Wrapped ETH'),
  [ChainId.BLAST]: new Token(ChainId.BLAST, '0x4300000000000000000000000000000000000004', 18, 'WETH', 'Wrapped ETH'),
  [ChainId.OPBNB]: new Token(ChainId.OPBNB, '0x4200000000000000000000000000000000000006', 18, 'WETH', 'Wrapped ETH'),
  [ChainId.MANTLE]: new Token(ChainId.MANTLE, '0x78c1b0C915c4FAA5FffA6CAbf0219DA63d7f4cb8', 18, 'WMNT', 'Wrapped MNT'),
  [ChainId.POLYGON_ZKEVM]: new Token(
    ChainId.POLYGON_ZKEVM,
    '0x4F9A0e7FD2Bf6067db6994CF12E4495Df938E6e9',
    18,
    'WETH',
    'Wrapped ETH'
  ),
}
