import invariant from 'tiny-invariant'
import { Currency } from './currency'
import { NativeCurrency } from './nativeCurrency'
import { Token } from './token'
import { WETH9 } from './weth9'
import { ChainId, ZERO_ADDRESS } from '../constants'

/**
 * Ether is the main usage of a 'native' currency, i.e. for Ethereum mainnet and all testnets
 */
export class Ether extends NativeCurrency {
  protected constructor(chainId: number) {
    super(chainId, 18, 'ETH', 'Ether')
  }

  public get wrapped(): Token {
    const weth9 = WETH9[this.chainId]
    invariant(!!weth9, 'WRAPPED')
    return weth9
  }

  private static _etherCache: { [chainId: number]: Ether } = {}

  public static onChain(chainId: number): Ether {
    return this._etherCache[chainId] ?? (this._etherCache[chainId] = new Ether(chainId))
  }

  public equals(other: Currency): boolean {
    return other.isNative && other.chainId === this.chainId
  }
}

//@todo merge CETH with Ether
export const CETH = {
  [ChainId.MUMBAI]: new Token(ChainId.MUMBAI, ZERO_ADDRESS, 18, 'MATIC', 'Matic'),
  [ChainId.MATIC]: new Token(ChainId.MATIC, ZERO_ADDRESS, 18, 'MATIC', 'Matic'),
  [ChainId.FUJI]: new Token(ChainId.AVALANCHE, ZERO_ADDRESS, 18, 'AVAX', 'Avalanche'),
  [ChainId.AVALANCHE]: new Token(ChainId.AVALANCHE, ZERO_ADDRESS, 18, 'AVAX', 'Avalanche'),
  [ChainId.FANTOM_TESTNET]: new Token(ChainId.FANTOM_TESTNET, ZERO_ADDRESS, 18, 'FTM', 'Fantom'),
  [ChainId.FANTOM]: new Token(ChainId.FANTOM, ZERO_ADDRESS, 18, 'FTM', 'Fantom'),
  [ChainId.HUOBIECO_TESTNET]: new Token(ChainId.HUOBIECO_TESTNET, ZERO_ADDRESS, 18, 'HT', 'Huobi ECO'),
  [ChainId.HUOBIECO]: new Token(ChainId.HUOBIECO, ZERO_ADDRESS, 18, 'HT', 'Huobi ECO'),
  [ChainId.REEF]: new Token(ChainId.REEF, ZERO_ADDRESS, 18, 'REEF', 'Reef'),
  [ChainId.XDAI]: new Token(ChainId.XDAI, ZERO_ADDRESS, 18, 'XDAI', 'xDai'),
  [ChainId.FUSE]: new Token(ChainId.FUSE, ZERO_ADDRESS, 18, 'FUSE', 'Fuse'),
  [ChainId.OPTIMISM]: new Token(ChainId.OPTIMISM, ZERO_ADDRESS, 18, 'ETH', 'Optimism'),
  [ChainId.OPTIMISM_TESTNET]: new Token(ChainId.OPTIMISM_TESTNET, ZERO_ADDRESS, 18, 'ETH', 'Optimism'),
  [ChainId.KUCOIN]: new Token(ChainId.KUCOIN, ZERO_ADDRESS, 18, 'KCS', 'Kucoin'),
  [ChainId.KUCOIN_TESTNET]: new Token(ChainId.KUCOIN_TESTNET, ZERO_ADDRESS, 18, 'tKCS', 'Kucoin'),
  [ChainId.ETHERLITE]: new Token(ChainId.ETHERLITE, ZERO_ADDRESS, 18, 'ETL', 'Etherlite'),
  [ChainId.ETHEREUM_CLASSIC]: new Token(ChainId.ETHEREUM_CLASSIC, ZERO_ADDRESS, 18, 'ETC', 'Ethereum Classic'),
  [ChainId.ETHEREUM_CLASSIC_TESTNET]: new Token(
    ChainId.ETHEREUM_CLASSIC_TESTNET,
    ZERO_ADDRESS,
    18,
    'CETC',
    'Ethereum Classic'
  ),
  [ChainId.MOONBEAM]: new Token(ChainId.MOONBEAM, ZERO_ADDRESS, 18, 'GLMR', 'Moonbeam'),
  [ChainId.MOONBEAM_TESTNET]: new Token(ChainId.MOONBEAM_TESTNET, ZERO_ADDRESS, 18, 'DEV', 'Moonbeam'),
  [ChainId.CLOVER]: new Token(ChainId.CLOVER, ZERO_ADDRESS, 18, 'CLV', 'Clover'),
  [ChainId.CLOVER_TESTNET]: new Token(ChainId.CLOVER_TESTNET, ZERO_ADDRESS, 18, 'CLV', 'Clover'),
  [ChainId.CELO]: new Token(ChainId.CELO, ZERO_ADDRESS, 18, 'CELO', 'Celo'),
  [ChainId.ALFAJORES]: new Token(ChainId.ALFAJORES, ZERO_ADDRESS, 18, 'CELO', 'Celo'),
  [ChainId.ARBITRUM_TESTNET]: new Token(ChainId.ARBITRUM_TESTNET, ZERO_ADDRESS, 18, 'ETH', 'Arbitrum'),
  [ChainId.ARBITRUM]: new Token(ChainId.ARBITRUM, ZERO_ADDRESS, 18, 'ETH', 'Arbitrum'),
  [ChainId.FLARE]: new Token(ChainId.FLARE, ZERO_ADDRESS, 18, 'FLR', 'Flare'),
  [ChainId.FLARE_TESTNET]: new Token(ChainId.FLARE_TESTNET, ZERO_ADDRESS, 18, 'CFLR', 'Flare'),
  [ChainId.FUSION]: new Token(ChainId.FUSION, ZERO_ADDRESS, 18, 'FSN', 'Fusion'),
  [ChainId.HARMONY]: new Token(ChainId.HARMONY, ZERO_ADDRESS, 18, 'ONE', 'Harmony'),
  [ChainId.OKEX]: new Token(ChainId.OKEX, ZERO_ADDRESS, 18, 'OKT', 'OKEx'),
  [ChainId.IOTEX]: new Token(ChainId.IOTEX, ZERO_ADDRESS, 18, 'IOTX', 'IoTeX'),
  [ChainId.HOO]: new Token(ChainId.HOO, ZERO_ADDRESS, 18, 'HOO', 'HOO'),
  [ChainId.ELAETH]: new Token(ChainId.ELAETH, ZERO_ADDRESS, 18, 'ELA', 'ELA'),
  [ChainId.MOONRIVER]: new Token(ChainId.MOONRIVER, ZERO_ADDRESS, 18, 'MOVR', 'MOVR'),
  [ChainId.TELOS]: new Token(ChainId.TELOS, ZERO_ADDRESS, 18, 'TLOS', 'TLOS'),
  [ChainId.CRONOS]: new Token(ChainId.CRONOS, ZERO_ADDRESS, 18, 'CRO', 'CRO'),
  [ChainId.KAVA]: new Token(ChainId.KAVA, ZERO_ADDRESS, 18, 'KAVA', 'Kava'),
  [ChainId.BTTC]: new Token(ChainId.BTTC, ZERO_ADDRESS, 18, 'BTT', 'BTT'),
  [ChainId.BTTC_TESTNET]: new Token(ChainId.BTTC_TESTNET, ZERO_ADDRESS, 18, 'BTT', 'BTT'),
  [ChainId.BINANCE_TESTNET]: new Token(ChainId.BINANCE_TESTNET, ZERO_ADDRESS, 18, 'BSC', 'Binance'),
  [ChainId.BINANCE]: new Token(ChainId.BINANCE, ZERO_ADDRESS, 18, 'BNB', 'Binance'),
  [ChainId.ROPSTEN]: new Token(ChainId.ROPSTEN, ZERO_ADDRESS, 18, 'ETH', 'Ethereum'),
  [ChainId.ETHEREUM]: new Token(ChainId.ETHEREUM, ZERO_ADDRESS, 18, 'ETH', 'Ethereum'),
  [ChainId.NEON_TESTNET]: new Token(ChainId.NEON_TESTNET, ZERO_ADDRESS, 18, 'NEON', 'Neon'),
  [ChainId.NEON_MAINNET]: new Token(ChainId.NEON_MAINNET, ZERO_ADDRESS, 18, 'NEON', 'Neon'),
  [ChainId.Q]: new Token(ChainId.Q, ZERO_ADDRESS, 18, 'QGOV', 'QGOV'),
  [ChainId.BITGERT]: new Token(ChainId.BITGERT, ZERO_ADDRESS, 18, 'BRISE', 'Brise'),
  [ChainId.WANCHAIN]: new Token(ChainId.WANCHAIN, ZERO_ADDRESS, 18, 'WAN', 'WAN'),
  [ChainId.METIS]: new Token(ChainId.METIS, ZERO_ADDRESS, 18, 'METIS', 'METIS'),
  [ChainId.ASTAR]: new Token(ChainId.ASTAR, ZERO_ADDRESS, 18, 'ASTR', 'Astar'),
  [ChainId.ARTHERA_TESTNET]: new Token(ChainId.ARTHERA_TESTNET, ZERO_ADDRESS, 18, 'AA', 'Arthera'),
  [ChainId.ARTHERA]: new Token(ChainId.ARTHERA, ZERO_ADDRESS, 18, 'AA', 'Arthera'),
  [ChainId.PULSECHAIN]: new Token(ChainId.PULSECHAIN, ZERO_ADDRESS, 18, 'PLS', 'Pulse'),
  [ChainId.BASE]: new Token(ChainId.BASE, ZERO_ADDRESS, 18, 'ETH', 'Ethereum'),
  [ChainId.VELAS]: new Token(ChainId.VELAS, ZERO_ADDRESS, 18, 'VLX', 'Velas'),
  [ChainId.LINEA]: new Token(ChainId.LINEA, ZERO_ADDRESS, 18, 'ETH', 'Ethereum'),
  [ChainId.ROOTSTOCK]: new Token(ChainId.ROOTSTOCK, ZERO_ADDRESS, 18, 'RBTC', 'Rootstock'),
  [ChainId.BOBA]: new Token(ChainId.BOBA, ZERO_ADDRESS, 18, 'ETH', 'Boba ETH'),
  [ChainId.BOBA_BNB]: new Token(ChainId.BOBA_BNB, ZERO_ADDRESS, 18, 'BOBA', 'Boba BNB'),
  [ChainId.AURORA]: new Token(ChainId.AURORA, ZERO_ADDRESS, 18, 'ETH', 'Aurora'),
  [ChainId.ZKSYNC]: new Token(ChainId.ZKSYNC, ZERO_ADDRESS, 18, 'ETH', 'ZkSync'),
  [ChainId.SCROLL]: new Token(ChainId.SCROLL, ZERO_ADDRESS, 18, 'ETH', 'Scroll'),
  [ChainId.BLAST]: new Token(ChainId.BLAST, ZERO_ADDRESS, 18, 'ETH', 'Blast'),
  [ChainId.OPBNB]: new Token(ChainId.OPBNB, ZERO_ADDRESS, 18, 'BNB', 'opBNB'),
  [ChainId.MANTLE]: new Token(ChainId.MANTLE, ZERO_ADDRESS, 18, 'MNT', 'Mantle'),
  [ChainId.POLYGON_ZKEVM]: new Token(ChainId.POLYGON_ZKEVM, ZERO_ADDRESS, 18, 'ETH', 'Polygon zkEVM'),
}

export const CETH_NAME = {
  [ChainId.MUMBAI]: 'MATIC',
  [ChainId.MATIC]: 'MATIC',
  [ChainId.FUJI]: 'AVAX',
  [ChainId.AVALANCHE]: 'AVAX',
  [ChainId.FANTOM_TESTNET]: 'FTM',
  [ChainId.FANTOM]: 'FTM',
  [ChainId.HUOBIECO_TESTNET]: 'HT',
  [ChainId.HUOBIECO]: 'HT',
  [ChainId.REEF]: 'REEF',
  [ChainId.XDAI]: 'xDAI',
  [ChainId.FUSE]: 'FUSE',
  [ChainId.OPTIMISM]: 'ETH',
  [ChainId.OPTIMISM_TESTNET]: 'KOR',
  [ChainId.KUCOIN]: 'KCS',
  [ChainId.KUCOIN_TESTNET]: 'tKCS',
  [ChainId.ETHERLITE]: 'ETL',
  [ChainId.ETHEREUM_CLASSIC]: 'ETC',
  [ChainId.ETHEREUM_CLASSIC_TESTNET]: 'CETC',
  [ChainId.MOONBEAM_TESTNET]: 'DEV',
  [ChainId.CLOVER_TESTNET]: 'CLV',
  [ChainId.CELO]: 'CELO',
  [ChainId.ALFAJORES]: 'CELO',
  [ChainId.ARBITRUM_TESTNET]: 'ETH',
  [ChainId.ARBITRUM]: 'ETH',
  [ChainId.FLARE]: 'FLR',
  [ChainId.FLARE_TESTNET]: 'CFLR',
  [ChainId.FUSION]: 'FSN',
  [ChainId.HARMONY]: 'ONE',
  [ChainId.OKEX]: 'OKT',
  [ChainId.IOTEX]: 'IOTX',
  [ChainId.HOO]: 'HOO',
  [ChainId.ELAETH]: 'ELA',
  [ChainId.MOONRIVER]: 'MOVR',
  [ChainId.TELOS]: 'TLOS',
  [ChainId.CRONOS]: 'CRO',
  [ChainId.KAVA]: 'KAVA',
  [ChainId.BTTC]: 'BTT',
  [ChainId.BTTC_TESTNET]: 'BTT',
  [ChainId.BINANCE_TESTNET]: 'BNB',
  [ChainId.BINANCE]: 'BNB',
  [ChainId.ROPSTEN]: 'ETH',
  [ChainId.ETHEREUM]: 'ETH',
  [ChainId.NEON_TESTNET]: 'tNEON',
  [ChainId.NEON_MAINNET]: 'NEON',
  [ChainId.Q]: 'QGOV',
  [ChainId.BITGERT]: 'BRISE',
  [ChainId.WANCHAIN]: 'WAN',
  [ChainId.METIS]: 'METIS',
  [ChainId.ASTAR]: 'ASTR',
  [ChainId.ARTHERA_TESTNET]: 'AA',
  [ChainId.ARTHERA]: 'AA',
  [ChainId.PULSECHAIN]: 'PLS',
  [ChainId.BASE]: 'ETH',
  [ChainId.VELAS]: 'VLX',
  [ChainId.LINEA]: 'ETH',
  [ChainId.ROOTSTOCK]: 'RBTC',
  [ChainId.BOBA]: 'ETH',
  [ChainId.BOBA_BNB]: 'BOBA',
  [ChainId.AURORA]: 'ETH',
  [ChainId.CLOVER]: 'CLV',
  [ChainId.ZKSYNC]: 'ETH',
  [ChainId.SCROLL]: 'ETH',
  [ChainId.BLAST]: 'ETH',
  [ChainId.OPBNB]: 'ETH',
  [ChainId.MOONBEAM]: 'GLMR',
  [ChainId.MANTLE]: 'MNT',
  [ChainId.POLYGON_ZKEVM]: 'ETH',
}

export const CETH_NAME_ROUTER = {
  [ChainId.MUMBAI]: 'MATIC',
  [ChainId.MATIC]: 'MATIC',
  [ChainId.FUJI]: 'AVAX',
  [ChainId.AVALANCHE]: 'AVAX',
  [ChainId.FANTOM_TESTNET]: 'FTM',
  [ChainId.FANTOM]: 'FTM',
  [ChainId.HUOBIECO_TESTNET]: 'HT',
  [ChainId.HUOBIECO]: 'HT',
  [ChainId.REEF]: 'ETH',
  [ChainId.XDAI]: 'xDAI',
  [ChainId.FUSE]: 'ETH',
  [ChainId.OPTIMISM]: 'ETH',
  [ChainId.OPTIMISM_TESTNET]: 'ETH',
  [ChainId.KUCOIN]: 'ETH',
  [ChainId.KUCOIN_TESTNET]: 'ETH',
  [ChainId.ETHERLITE]: 'ETH',
  [ChainId.ETHEREUM_CLASSIC]: 'ETH',
  [ChainId.ETHEREUM_CLASSIC_TESTNET]: 'ETH',
  [ChainId.MOONBEAM_TESTNET]: 'ETH',
  [ChainId.CLOVER_TESTNET]: 'ETH',
  [ChainId.CELO]: 'ETH',
  [ChainId.ALFAJORES]: 'ETH',
  [ChainId.ARBITRUM_TESTNET]: 'ETH',
  [ChainId.ARBITRUM]: 'ETH',
  [ChainId.FLARE]: 'ETH',
  [ChainId.FLARE_TESTNET]: 'ETH',
  [ChainId.FUSION]: 'ETH',
  [ChainId.HARMONY]: 'ETH',
  [ChainId.OKEX]: 'ETH',
  [ChainId.IOTEX]: 'ETH',
  [ChainId.HOO]: 'ETH',
  [ChainId.ELAETH]: 'ETH',
  [ChainId.MOONRIVER]: 'ETH',
  [ChainId.TELOS]: 'ETH',
  [ChainId.CRONOS]: 'ETH',
  [ChainId.KAVA]: 'ETH',
  [ChainId.BTTC]: 'ETH',
  [ChainId.BTTC_TESTNET]: 'ETH',
  [ChainId.BINANCE_TESTNET]: 'BNB',
  [ChainId.BINANCE]: 'BNB',
  [ChainId.ROPSTEN]: 'ETH',
  [ChainId.ETHEREUM]: 'ETH',
  [ChainId.NEON_TESTNET]: 'ETH',
  [ChainId.NEON_MAINNET]: 'ETH',
  [ChainId.Q]: 'ETH',
  [ChainId.BITGERT]: 'ETH',
  [ChainId.WANCHAIN]: 'ETH',
  [ChainId.METIS]: 'ETH',
  [ChainId.ASTAR]: 'ETH',
  [ChainId.ARTHERA_TESTNET]: 'ETH',
  [ChainId.ARTHERA]: 'ETH',
  [ChainId.PULSECHAIN]: 'ETH',
  [ChainId.BASE]: 'ETH',
  [ChainId.VELAS]: 'ETH',
  [ChainId.LINEA]: 'ETH',
  [ChainId.ROOTSTOCK]: 'ETH',
  [ChainId.BOBA]: 'ETH',
  [ChainId.BOBA_BNB]: 'ETH',
  [ChainId.AURORA]: 'ETH',
  [ChainId.CLOVER]: 'ETH',
  [ChainId.ZKSYNC]: 'ETH',
  [ChainId.SCROLL]: 'ETH',
  [ChainId.BLAST]: 'ETH',
  [ChainId.OPBNB]: 'ETH',
  [ChainId.MOONBEAM]: 'ETH',
  [ChainId.MANTLE]: 'ETH',
  [ChainId.POLYGON_ZKEVM]: 'ETH',
}
