import JSBI from 'jsbi'

export const ZERO_ADDRESS = '0x0000000000000000000000000000000000000000'

export const FACTORY_ADDRESS: { [chainId in number]: string } = {
  [80001]: '0x14d280b36eb9cEfF93e9e75fd91f046c54DB2C17',
  [137]: '0xE3BD06c7ac7E1CeB17BdD2E5BA83E40D1515AF2a',
  [43113]: '0x7941856Ab590351EBc48FE9b68F17A3864aB6Df5',
  [43114]: '0x091d35d7F63487909C863001ddCA481c6De47091',
  [4002]: '0x7Ba73c99e6f01a37f3e33854c8F544BbbadD3420',
  [250]: '0x7Ba73c99e6f01a37f3e33854c8F544BbbadD3420',
  [256]: '0x379f1437267B0F069c4856953bE43c42b03795da',
  [128]: '0x997fCE9164D630CC58eE366d4D275B9D773d54A4',
  [13939]: ZERO_ADDRESS,
  [100]: '0xCB018587dA9590A18f49fFE2b85314c33aF3Ad3B',
  [122]: '0x779407e40Dad9D70Ba5ADc30E45cC3494ec71ad2',
  [10]: '0xedfad3a0F42A8920B011bb0332aDe632e552d846',
  [69]: ZERO_ADDRESS,
  [321]: '0x1f9aa39001ed0630dA6854859D7B3eD255648599',
  [322]: ZERO_ADDRESS,
  [111]: ZERO_ADDRESS,
  [61]: ZERO_ADDRESS,
  [62]: ZERO_ADDRESS,
  [1284]: '0x69D10bc18cD588A4b70F836a471D4e9C2FD86092',
  [1024]: '0x89e73ddAc08FDe010e54dF19d5FA2658b0931469',
  [1287]: ZERO_ADDRESS,
  [124]: ZERO_ADDRESS,
  [123]: ZERO_ADDRESS,
  [42220]: ZERO_ADDRESS,
  [44787]: ZERO_ADDRESS,
  [421611]: ZERO_ADDRESS,
  [42161]: '0xA59B2044EAFD15ee4deF138D410d764c9023E1F0',
  [14]: ZERO_ADDRESS,
  [16]: ZERO_ADDRESS,
  [32659]: ZERO_ADDRESS,
  [1666600000]: '0xCdde1AbfF5Ae3Cbfbdb55c1e866Ac56380e18720',
  [66]: '0x1116f8B82028324f2065078b4ff6b47F1Cc22B97',
  [4689]: '0xF96bE66DA0b9bC9DFD849827b4acfA7e8a6F3C42',
  [70]: '0x9c03E724455306491BfD2CE0805fb872727313eA',
  [20]: '0x440a1B8b8e968D6765D41E6b92DF3cBb0e9D2b1e',
  [1285]: '0xd45145f10fD4071dfC9fC3b1aefCd9c83A685e77',
  [40]: '0x47c3163e691966f8c1b93B308A236DDB3C1C592d',
  [25]: '0xEEa0e2830D09D8786Cb9F484cA20898b61819ef1',
  [2222]: '0xC012C4b3d253A8F22d5e4ADA67ea2236FF9778fc',
  [199]: '0xc06348AEE3f3E92eE452816E0D3F25C919F6fB04',
  [1029]: '0x46c4f54f87889fbd93df80d8aaba500268f2dda8',
  [97]: ZERO_ADDRESS,
  [56]: '0x31aFfd875e9f68cd6Cd12Cee8943566c9A4bBA13',
  [3]: ZERO_ADDRESS,
  [1]: '0x6511eBA915fC1b94b2364289CCa2b27AE5898d80',
  [245022926]: '0xfbb4E52FEcc90924c79F980eb24a9794ae4aFFA4',
  [245022934]: '0xfbb4E52FEcc90924c79F980eb24a9794ae4aFFA4',
  [35441]: '0xfbb4E52FEcc90924c79F980eb24a9794ae4aFFA4',
  [32520]: '0xfbb4E52FEcc90924c79F980eb24a9794ae4aFFA4',
  [888]: '0xfbb4E52FEcc90924c79F980eb24a9794ae4aFFA4',
  [1088]: '0xfbb4E52FEcc90924c79F980eb24a9794ae4aFFA4',
  [592]: '0xfbb4E52FEcc90924c79F980eb24a9794ae4aFFA4',
  [10243]: '0x421a112ab4054eE33F7dcdaD7C2bd19aDe6E6D63',
  [10242]: '0x69D10bc18cD588A4b70F836a471D4e9C2FD86092',
  [369]: '0xfbb4E52FEcc90924c79F980eb24a9794ae4aFFA4',
  [8453]: '0xfbb4E52FEcc90924c79F980eb24a9794ae4aFFA4',
  [106]: '0xfbb4E52FEcc90924c79F980eb24a9794ae4aFFA4',
  [59144]: '0xfbb4E52FEcc90924c79F980eb24a9794ae4aFFA4',
  [30]: '0x69d10bc18cd588a4b70f836a471d4e9c2fd86092',
  [288]: '0x69D10bc18cD588A4b70F836a471D4e9C2FD86092',
  [56288]: '0x69D10bc18cD588A4b70F836a471D4e9C2FD86092',
  [1313161554]: '0x69D10bc18cD588A4b70F836a471D4e9C2FD86092',
  [324]: '0x69D10bc18cD588A4b70F836a471D4e9C2FD86092',
  [534352]: '0x69D10bc18cD588A4b70F836a471D4e9C2FD86092',
  [238]: '0x69D10bc18cD588A4b70F836a471D4e9C2FD86092',
  [204]: '0x69D10bc18cD588A4b70F836a471D4e9C2FD86092',
  [5000]: '0x69D10bc18cD588A4b70F836a471D4e9C2FD86092',
  [1101]: '0x69D10bc18cD588A4b70F836a471D4e9C2FD86092'
}

export const INIT_CODE_HASH: { [chainId in number]: string } = {
  [80001]: '0x84845e7ccb283dec564acfcd3d9287a491dec6d675705545a2ab8be22ad78f31',
  [137]: '0x84845e7ccb283dec564acfcd3d9287a491dec6d675705545a2ab8be22ad78f31',
  [43113]: '0x33c4831a098654d3d20a78641a198ee6ffc1ceed49f2196b75bb244891c260e3',
  [43114]: '0x33c4831a098654d3d20a78641a198ee6ffc1ceed49f2196b75bb244891c260e3',
  [4002]: '0x84845e7ccb283dec564acfcd3d9287a491dec6d675705545a2ab8be22ad78f31',
  [250]: '0x84845e7ccb283dec564acfcd3d9287a491dec6d675705545a2ab8be22ad78f31',
  [256]: '0x84845e7ccb283dec564acfcd3d9287a491dec6d675705545a2ab8be22ad78f31',
  [128]: '0x84845e7ccb283dec564acfcd3d9287a491dec6d675705545a2ab8be22ad78f31',
  [13939]: '',
  [100]: '0x84845e7ccb283dec564acfcd3d9287a491dec6d675705545a2ab8be22ad78f31',
  [122]: '0x84845e7ccb283dec564acfcd3d9287a491dec6d675705545a2ab8be22ad78f31',
  [10]: '0x84845e7ccb283dec564acfcd3d9287a491dec6d675705545a2ab8be22ad78f31',
  [69]: '',
  [321]: '0x84845e7ccb283dec564acfcd3d9287a491dec6d675705545a2ab8be22ad78f31',
  [322]: '0x84845e7ccb283dec564acfcd3d9287a491dec6d675705545a2ab8be22ad78f31',
  [111]: '',
  [61]: '',
  [62]: '',
  [1284]: '0x84845e7ccb283dec564acfcd3d9287a491dec6d675705545a2ab8be22ad78f31',
  [1024]: '0x84845e7ccb283dec564acfcd3d9287a491dec6d675705545a2ab8be22ad78f31',
  [1287]: '',
  [124]: '',
  [123]: '',
  [42220]: '',
  [44787]: '',
  [421611]: '0x84845e7ccb283dec564acfcd3d9287a491dec6d675705545a2ab8be22ad78f31',
  [42161]: '0x84845e7ccb283dec564acfcd3d9287a491dec6d675705545a2ab8be22ad78f31',
  [14]: '',
  [16]: '',
  [32659]: '',
  [1666600000]: '0x84845e7ccb283dec564acfcd3d9287a491dec6d675705545a2ab8be22ad78f31',
  [66]: '0x84845e7ccb283dec564acfcd3d9287a491dec6d675705545a2ab8be22ad78f31',
  [4689]: '0x84845e7ccb283dec564acfcd3d9287a491dec6d675705545a2ab8be22ad78f31',
  [70]: '0x84845e7ccb283dec564acfcd3d9287a491dec6d675705545a2ab8be22ad78f31',
  [20]: '0x84845e7ccb283dec564acfcd3d9287a491dec6d675705545a2ab8be22ad78f31',
  [1285]: '0x84845e7ccb283dec564acfcd3d9287a491dec6d675705545a2ab8be22ad78f31',
  [40]: '0x84845e7ccb283dec564acfcd3d9287a491dec6d675705545a2ab8be22ad78f31',
  [25]: '0x84845e7ccb283dec564acfcd3d9287a491dec6d675705545a2ab8be22ad78f31',
  [2222]: '0x84845e7ccb283dec564acfcd3d9287a491dec6d675705545a2ab8be22ad78f31',
  [199]: '0x84845e7ccb283dec564acfcd3d9287a491dec6d675705545a2ab8be22ad78f31',
  [1029]: '0x84845e7ccb283dec564acfcd3d9287a491dec6d675705545a2ab8be22ad78f31',
  [97]: '0x84845e7ccb283dec564acfcd3d9287a491dec6d675705545a2ab8be22ad78f31',
  [56]: '0x84845e7ccb283dec564acfcd3d9287a491dec6d675705545a2ab8be22ad78f31',
  [3]: '0x84845e7ccb283dec564acfcd3d9287a491dec6d675705545a2ab8be22ad78f31',
  [1]: '0x84845e7ccb283dec564acfcd3d9287a491dec6d675705545a2ab8be22ad78f31',
  [245022926]: '0x074ce6e2b043b11e990c9b71d400ce5b2c39c96ddad65144d0a879d31c2bbaf9',
  [245022934]: '0x84845e7ccb283dec564acfcd3d9287a491dec6d675705545a2ab8be22ad78f31',
  [35441]: '0x074ce6e2b043b11e990c9b71d400ce5b2c39c96ddad65144d0a879d31c2bbaf9',
  [32520]: '0x074ce6e2b043b11e990c9b71d400ce5b2c39c96ddad65144d0a879d31c2bbaf9',
  [888]: '0x074ce6e2b043b11e990c9b71d400ce5b2c39c96ddad65144d0a879d31c2bbaf9',
  [1088]: '0x074ce6e2b043b11e990c9b71d400ce5b2c39c96ddad65144d0a879d31c2bbaf9',
  [592]: '0x074ce6e2b043b11e990c9b71d400ce5b2c39c96ddad65144d0a879d31c2bbaf9',
  [10243]: '0x84845e7ccb283dec564acfcd3d9287a491dec6d675705545a2ab8be22ad78f31',
  [10242]: '0x84845e7ccb283dec564acfcd3d9287a491dec6d675705545a2ab8be22ad78f31',
  [369]: '0x074ce6e2b043b11e990c9b71d400ce5b2c39c96ddad65144d0a879d31c2bbaf9',
  [8453]: '0x84845e7ccb283dec564acfcd3d9287a491dec6d675705545a2ab8be22ad78f31',
  [106]: '0x84845e7ccb283dec564acfcd3d9287a491dec6d675705545a2ab8be22ad78f31',
  [59144]: '0x84845e7ccb283dec564acfcd3d9287a491dec6d675705545a2ab8be22ad78f31',
  [30]: '0x84845e7ccb283dec564acfcd3d9287a491dec6d675705545a2ab8be22ad78f31',
  [288]: '0x84845e7ccb283dec564acfcd3d9287a491dec6d675705545a2ab8be22ad78f31',
  [56288]: '0x84845e7ccb283dec564acfcd3d9287a491dec6d675705545a2ab8be22ad78f31',
  [1313161554]: '0x84845e7ccb283dec564acfcd3d9287a491dec6d675705545a2ab8be22ad78f31',
  [324]: '0x84845e7ccb283dec564acfcd3d9287a491dec6d675705545a2ab8be22ad78f31',
  [534352]: '0x84845e7ccb283dec564acfcd3d9287a491dec6d675705545a2ab8be22ad78f31',
  [238]: '0x84845e7ccb283dec564acfcd3d9287a491dec6d675705545a2ab8be22ad78f31',
  [204]: '0x84845e7ccb283dec564acfcd3d9287a491dec6d675705545a2ab8be22ad78f31',
  [5000]: '0x84845e7ccb283dec564acfcd3d9287a491dec6d675705545a2ab8be22ad78f31',
  [1101]: '0x84845e7ccb283dec564acfcd3d9287a491dec6d675705545a2ab8be22ad78f31'
}

//@todo replace with CETH_NAME_ROUTER from @elkdex/sdk
export const CETH_NAME_ROUTER: { [chainId in number]: string } = {
  [80001]: 'MATIC',
  [137]: 'MATIC',
  [43113]: 'AVAX',
  [43114]: 'AVAX',
  [4002]: 'FTM',
  [250]: 'FTM',
  [256]: 'HT',
  [128]: 'HT',
  [13939]: 'ETH',
  [100]: 'xDAI',
  [122]: 'ETH',
  [10]: 'ETH',
  [69]: 'ETH',
  [321]: 'ETH',
  [322]: 'ETH',
  [111]: 'ETH',
  [61]: 'ETH',
  [62]: 'ETH',
  [1284]: 'ETH',
  [1024]: 'ETH',
  [1287]: 'ETH',
  [124]: 'ETH',
  [123]: 'ETH',
  [42220]: 'ETH',
  [44787]: 'ETH',
  [421611]: 'ETH',
  [42161]: 'ETH',
  [14]: 'ETH',
  [16]: 'ETH',
  [32659]: 'ETH',
  [1666600000]: 'ETH',
  [66]: 'ETH',
  [4689]: 'ETH',
  [70]: 'ETH',
  [20]: 'ETH',
  [1285]: 'ETH',
  [40]: 'ETH',
  [25]: 'ETH',
  [2222]: 'ETH',
  [199]: 'ETH',
  [1029]: 'ETH',
  [97]: 'BNB',
  [56]: 'BNB',
  [3]: 'ETH',
  [1]: 'ETH',
  [245022926]: 'ETH',
  [245022934]: 'ETH',
  [35441]: 'ETH',
  [32520]: 'ETH',
  [888]: 'ETH',
  [1088]: 'ETH',
  [592]: 'ETH',
  [10243]: 'ETH',
  [10242]: 'ETH',
  [369]: 'ETH',
  [8453]: 'ETH',
  [106]: 'ETH',
  [59144]: 'ETH',
  [30]: 'ETH',
  [288]: 'ETH',
  [56288]: 'ETH',
  [1313161554]: 'ETH',
  [324]: 'ETH',
  [534352]: 'ETH',
  [238]: 'ETH',
  [204]: 'ETH',
  [5000]: 'ETH',
  [1101]: 'ETH'
}

export const MINIMUM_LIQUIDITY = JSBI.BigInt(1000)

// exports for internal consumption
export const ZERO = JSBI.BigInt(0)
export const ONE = JSBI.BigInt(1)
export const FIVE = JSBI.BigInt(5)
export const _997 = JSBI.BigInt(997)
export const _1000 = JSBI.BigInt(1000)
